import { ROLES } from 'constants/roleAndPermission.constant';
import { Link } from 'react-router-dom';
import { Role } from 'types/common';
import { ObjectOption } from '../types';

export const customFormatLabel = (
  optionObject: ObjectOption,
  condition?: string,
  role?: Role
) => {
  const { recoveryCourseItem } = optionObject;
  const linkToNavigate =
    role !== ROLES.CompanyManager
      ? `/courses/view/${recoveryCourseItem?.slug}`
      : `/manager/courses/${recoveryCourseItem?.slug}`;
  switch (condition) {
    case 'courseRecover': {
      return (
        <div className="my-2">
          <div className="font-semibold">
            <Link className="hover:underline" to={linkToNavigate} target="_blank">
              {recoveryCourseItem?.progressive_number} - {recoveryCourseItem?.title}
            </Link>
          </div>
          <div>
            {recoveryCourseItem?.start_date} - {recoveryCourseItem?.end_date}
            {' - '}
            {recoveryCourseItem?.mode} - {recoveryCourseItem?.type}
          </div>
        </div>
      );
    }

    default: {
      return <></>;
    }
  }
};
