// **components**
import Button from 'components/Button/Button';
import Image from 'components/Image';
import { REACT_APP_FRONT_URL } from 'config';
import { IExamModal } from 'modules/Courses/types';

// **hooks**
import { useQueryGetFunction } from 'hooks/useQuery';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// **redux**
import StatusLabel from 'components/StatusLabel';
import Table from 'components/Table/Table';
import { CellProps, ITableHeaderProps } from 'components/Table/types';
import _ from 'lodash';
import { FetchAttendeeDetails } from 'modules/CompanyManager/types';
import { ExamEnum } from 'modules/Courses/pages/CompanyParticipantAttendance/Enum';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currentPageSelector } from 'redux-toolkit/slices/paginationSlice';
import { clearToken } from 'redux-toolkit/slices/tokenSlice';

type examProps = {
  slug?: string;
};
const ExamModal = ({ slug }: examProps) => {
  const { t } = useTranslation();
  const [exam, setExam] = useState<IExamModal>();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState<number>(10);
  const [sort, setSort] = useState<string>('-updated_at');
  const { currentPage } = useSelector(currentPageSelector);

  const { response, isLoading } = useQueryGetFunction(`/trainer/exam/qr`, {
    option: {
      course_slug: slug,
    },
  });

  const { response: AttendeeData, isLoading: AttendeeLoader } = useQueryGetFunction(
    '/course/participates',
    {
      option: {
        page: currentPage,
        course_slug: slug,
        limit,
        sort,
      },
    }
  );
  useEffect(() => {
    if (response) {
      setExam(response?.data);
    }
  }, [response]);

  const handleClickLink = () => {
    dispatch(clearToken());
  };
  const columnData: ITableHeaderProps[] = [
    {
      header: t('UserManagement.columnHeader.no'),
      name: 'no',
      className: '',
      option: {
        sort: false,
        hasFilter: false,
        isIndex: true,
      },
    },
    {
      name: 'first_name',
      header: t('CompanyManager.AttendeeList.columnsTitles.name'),
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props) => nameRender(props as unknown as FetchAttendeeDetails),
    },
    {
      name: 'email',
      header: t('Auth.Login.email'),
      option: {
        sort: false,
        hasFilter: false,
      },
    },
    {
      name: 'code',
      header: t('socialSecurityNumber'),
      option: {
        sort: false,
        hasFilter: false,
      },
    },
    {
      name: 'job_title',
      header: t('CompanyManager.AttendeeList.columnsTitles.role'),
      option: {
        sort: false,
        hasFilter: false,
      },
    },
    {
      name: 'mobile_number',
      header: t('CompanyManager.AttendeeList.columnsTitles.mobileNo'),
      option: {
        sort: false,
        hasFilter: false,
      },
    },
    {
      name: 'company.company_name',
      header: t('Quote.company.detail.nameTitle'),
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props: CellProps) =>
        companyTitleRender(props as unknown as FetchAttendeeDetails),
    },
    {
      name: '',
      header: t('attendees.attendance.percentage'),
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props: CellProps) => percentageRender(props),
    },
    {
      name: '',
      header: t('attendees.exam.result.statusTitle'),
      option: {
        sort: false,
        hasFilter: false,
      },
      cell: (props: CellProps) =>
        examStatusRender(props as unknown as FetchAttendeeDetails),
    },
  ];

  const percentageRender = (item: CellProps) => {
    if (
      !_.isNull(item?.courseAttendance) &&
      !_.isUndefined(item?.courseAttendance)
    ) {
      return `${item?.courseAttendance}%`;
    }
    return '-';
  };

  const companyTitleRender = (item: FetchAttendeeDetails) => {
    const companyName = item?.company?.name;
    const commonContent = (
      <div className="flex flex-col">
        <span>{companyName}</span>
      </div>
    );
    return companyName ? commonContent : '-';
  };

  const examStatusRender = (item: FetchAttendeeDetails) => {
    let status = '';
    const checkIfPass = item?.courseParticipateExam.some(
      (e) => e?.status_without_attendance === 'pass'
    );
    if (item?.courseParticipateExam.length > 0) {
      status = item?.courseParticipateExam.length
        ? checkIfPass
          ? 'pass'
          : item?.courseParticipateExam[item.courseParticipateExam.length - 1]
              ?.status_without_attendance
        : '';
    }
    return (
      <StatusLabel
        text={
          status === ExamEnum.PASS
            ? t('attendees.exam.pass')
            : status === ExamEnum.FAIL
            ? t('attendees.exam.fail')
            : t('attendees.exam.notAttempted')
        }
        variants={
          status === ExamEnum.PASS
            ? 'completed'
            : status === ExamEnum.FAIL
            ? 'cancelled'
            : 'pending'
        }
      />
    );
  };
  const nameRender = (item: FetchAttendeeDetails) => {
    if (item?.full_name) {
      item.full_name = `${item.full_name}`;
    }
    return (
      <div className="flex flex-col">
        <span>{item?.full_name}</span>
        {item?.recovered_from_course_id ? (
          <StatusLabel text={t('RecoveredCourse.recovered')} variants="completed" />
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        <>
          <p className="lazy w-full h-6" />
          <div className="lazy mx-auto max-w-[650px] max-h-[650px] rounded-lg overflow-hidden my-8" />
          <div className="mx-auto flex flex-col justify-center items-center gap-4">
            <span className="lazy block text-center w-24 h-6" />
            <div className="lazy w-full px-5 py-4 flex gap-2.5 rounded-lg items-center" />
          </div>
        </>
      ) : (
        <>
          <p className="text-2xl font-bold bg-[#c2ff00] max-w-max p-2 sss">
            {exam?.title}
          </p>
          <p className="text-xl font-bold">{t('ExamModal.message')}</p>
          <div className="flex">
            <div className="w-[25%]">
              <div className="mx-auto max-w-[600px] max-h-[600px] rounded-lg overflow-hidden border border-solid border-navText/30 mb-8 mt-4">
                <Image imgClassName="w-full h-full" src={exam?.exam[0]?.qr_string} />
              </div>
              <div className="mx-auto flex flex-col justify-center items-center gap-4">
                <label className="text-base text-grayText block text-center">
                  {t('ExamModal.link')}
                </label>
                <div className="w-full">
                  <div className="border border-s-borderColor border-navText/30 px-5 py-3 flex gap-2.5 rounded-lg items-center">
                    {exam?.exam[0]?.url && (
                      <Button
                        className="flex-[1_0_0%] inline-block overflow-hidden whitespace-nowrap text-sm text-primary text-center"
                        onClickHandler={handleClickLink}
                      >
                        <Link
                          to={`${REACT_APP_FRONT_URL}/${exam?.exam?.[0]?.url}`}
                          target="_blank"
                        >
                          {`${REACT_APP_FRONT_URL}/${exam?.exam?.[0]?.url}`}
                        </Link>
                      </Button>
                    )}

                    <Button
                      className=""
                      onClickHandler={() => {
                        navigator.clipboard.writeText(
                          `${REACT_APP_FRONT_URL}/${exam?.exam?.[0]?.url}`
                        );
                      }}
                      tooltipText={t('copyText')}
                      tooltipPosition="left"
                    >
                      <Image
                        iconName="copyIcon"
                        iconClassName="w-4 h-4 text-primary cursor-pointer"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[75%]">
              <Table
                headerData={columnData}
                bodyData={AttendeeData?.data?.data}
                loader={AttendeeLoader}
                pagination
                dataPerPage={limit}
                setLimit={setLimit}
                totalPage={AttendeeData?.data?.lastPage}
                dataCount={AttendeeData?.data?.count}
                setSort={setSort}
                sort={sort}
                tableHeightClassName="!min-h-[unset]"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ExamModal;
